const MicIcon = ({
  className = '',
  size = '16px',
  title = 'Mic',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 17.453125 0 C 16.125 0 14.839844 0.398438 13.746094 1.15625 C 13.613281 1.246094 13.527344 1.390625 13.511719 1.554688 C 13.496094 1.714844 13.554688 1.875 13.667969 1.988281 L 22.011719 10.332031 C 22.113281 10.433594 22.253906 10.492188 22.398438 10.492188 C 22.414062 10.492188 22.429688 10.488281 22.445312 10.488281 C 22.609375 10.472656 22.753906 10.386719 22.847656 10.253906 C 23.601562 9.15625 24 7.875 24 6.546875 C 24 2.9375 21.0625 0 17.453125 0 Z M 17.453125 0' />
      <path d='M 12.898438 2.761719 C 12.785156 2.644531 12.636719 2.589844 12.460938 2.601562 C 12.300781 2.617188 12.15625 2.703125 12.0625 2.835938 C 11.308594 3.933594 10.910156 5.214844 10.910156 6.546875 C 10.910156 10.15625 13.84375 13.089844 17.453125 13.089844 C 18.785156 13.089844 20.066406 12.691406 21.164062 11.9375 C 21.296875 11.84375 21.382812 11.699219 21.398438 11.535156 C 21.414062 11.375 21.355469 11.214844 21.238281 11.101562 Z M 12.898438 2.761719' />
      <path d='M 14.785156 13.691406 C 12.714844 12.914062 11.085938 11.285156 10.308594 9.214844 C 10.242188 9.03125 10.078125 8.898438 9.886719 8.867188 C 9.695312 8.835938 9.496094 8.910156 9.375 9.0625 L 0.363281 20.246094 C 0.332031 20.285156 0.308594 20.328125 0.289062 20.375 C 0.0976562 20.648438 0 20.957031 0 21.273438 C 0 21.710938 0.171875 22.121094 0.480469 22.429688 L 1.570312 23.519531 C 1.878906 23.828125 2.289062 24 2.726562 24 C 3.042969 24 3.347656 23.902344 3.625 23.714844 C 3.671875 23.695312 3.714844 23.667969 3.753906 23.636719 L 14.933594 14.625 C 15.085938 14.503906 15.164062 14.308594 15.132812 14.113281 C 15.101562 13.921875 14.96875 13.757812 14.785156 13.691406 Z M 10.75 14.023438 L 9.660156 15.113281 C 9.550781 15.21875 9.414062 15.273438 9.273438 15.273438 C 9.132812 15.273438 8.992188 15.21875 8.886719 15.113281 C 8.675781 14.898438 8.675781 14.554688 8.886719 14.339844 L 9.976562 13.25 C 10.191406 13.039062 10.535156 13.039062 10.75 13.25 C 10.960938 13.464844 10.960938 13.808594 10.75 14.023438 Z M 10.75 14.023438' />
    </g>
  </svg>
)

export default MicIcon