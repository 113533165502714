const ListIcon = ({
  className = '',
  size = '26px',
  title = 'List',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 64 64'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 57.14 0 L 6.86 0 C 3 0 0 3 0 6.75 L 0 56.25 C 0 59.98 3 63 6.86 63 L 57.14 63 C 60.93 63 64 59.98 64 56.25 L 64 6.75 C 64 3 60.93 0 57.14 0 Z M 20.57 49.5 L 13.71 49.5 C 12.45 49.5 11.43 48.49 11.43 47.25 C 11.43 46 12.45 45 13.71 45 L 20.57 45 C 21.83 45 22.86 46 22.86 47.25 C 22.86 48.49 21.83 49.5 20.57 49.5 Z M 20.57 33.75 L 13.71 33.75 C 12.45 33.75 11.43 32.74 11.43 31.5 C 11.43 30.26 12.45 29.25 13.71 29.25 L 20.57 29.25 C 21.83 29.25 22.86 30.26 22.86 31.5 C 22.86 32.74 21.83 33.75 20.57 33.75 Z M 20.57 18 L 13.71 18 C 12.45 18 11.43 16.99 11.43 15.75 C 11.43 14.51 12.45 13.5 13.71 13.5 L 20.57 13.5 C 21.83 13.5 22.86 14.51 22.86 15.75 C 22.86 16.99 21.83 18 20.57 18 Z M 50.29 49.5 L 29.71 49.5 C 28.45 49.5 27.43 48.49 27.43 47.25 C 27.43 46 28.45 45 29.71 45 L 50.29 45 C 51.55 45 52.57 46 52.57 47.25 C 52.57 48.49 51.55 49.5 50.29 49.5 Z M 50.29 33.75 L 29.71 33.75 C 28.45 33.75 27.43 32.74 27.43 31.5 C 27.43 30.26 28.45 29.25 29.71 29.25 L 50.29 29.25 C 51.55 29.25 52.57 30.26 52.57 31.5 C 52.57 32.74 51.55 33.75 50.29 33.75 Z M 50.29 18 L 29.71 18 C 28.45 18 27.43 16.99 27.43 15.75 C 27.43 14.51 28.45 13.5 29.71 13.5 L 50.29 13.5 C 51.55 13.5 52.57 14.51 52.57 15.75 C 52.57 16.99 51.55 18 50.29 18 Z M 50.29 18' />
    </g>
  </svg>
)

export default ListIcon