const TrashIcon = ({
  className = '',
  size = '32px',
  title = 'Trash',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 48 48'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 16.171875 4.363281 C 16.171875 2.929688 17.289062 1.886719 18.71875 1.886719 L 29.1875 1.886719 C 30.617188 1.886719 31.734375 2.929688 31.734375 4.363281 L 31.734375 7.074219 L 33.617188 7.074219 L 33.617188 4.363281 C 33.617188 1.890625 31.65625 0 29.1875 0 L 18.71875 0 C 16.25 0 14.285156 1.890625 14.285156 4.363281 L 14.285156 7.074219 L 16.171875 7.074219 Z M 16.171875 4.363281' />
      <path d='M 12.800781 47.976562 L 35.105469 47.976562 C 37.253906 47.976562 38.921875 46.085938 38.921875 43.734375 L 38.921875 14.617188 L 8.980469 14.617188 L 8.980469 43.734375 C 8.980469 46.085938 10.652344 47.976562 12.800781 47.976562 Z M 29.964844 19.121094 C 29.964844 18.597656 30.386719 18.175781 30.90625 18.175781 C 31.429688 18.175781 31.851562 18.597656 31.851562 19.121094 L 31.851562 41.398438 C 31.851562 41.917969 31.429688 42.339844 30.90625 42.339844 C 30.386719 42.339844 29.964844 41.917969 29.964844 41.398438 Z M 23.007812 19.121094 C 23.007812 18.597656 23.433594 18.175781 23.953125 18.175781 C 24.472656 18.175781 24.894531 18.597656 24.894531 19.121094 L 24.894531 41.398438 C 24.894531 41.917969 24.472656 42.339844 23.953125 42.339844 C 23.433594 42.339844 23.007812 41.917969 23.007812 41.398438 Z M 16.054688 19.121094 C 16.054688 18.597656 16.476562 18.175781 16.996094 18.175781 C 17.519531 18.175781 17.941406 18.597656 17.941406 19.121094 L 17.941406 41.398438 C 17.941406 41.917969 17.519531 42.339844 16.996094 42.339844 C 16.476562 42.339844 16.054688 41.917969 16.054688 41.398438 Z M 16.054688 19.121094' />
      <path d='M 8.015625 12.730469 L 39.890625 12.730469 C 41.191406 12.730469 42.246094 11.675781 42.246094 10.375 C 42.246094 9.070312 41.191406 8.015625 39.890625 8.015625 L 8.015625 8.015625 C 6.714844 8.015625 5.65625 9.070312 5.65625 10.375 C 5.65625 11.675781 6.714844 12.730469 8.015625 12.730469 Z M 8.015625 12.730469' />
    </g>
  </svg>
)

export default TrashIcon