const LockFilledIcon = ({
  className = '',
  size = '24px',
  title = 'Lock',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 48 48'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 21.246094 0.3125 C 17.761719 0.753906 14.890625 1.9375 13.246094 3.589844 C 12.570312 4.265625 12.160156 4.90625 11.867188 5.75 L 11.644531 6.355469 L 11.644531 18.84375 L 9.21875 18.84375 C 6.460938 18.84375 6.195312 18.890625 5.03125 19.484375 C 4.054688 19.992188 3.191406 20.851562 2.683594 21.832031 C 2.007812 23.15625 2.042969 22.453125 2.042969 33.316406 C 2.042969 44.195312 2.007812 43.511719 2.691406 44.84375 C 3.183594 45.796875 4.054688 46.667969 5.023438 47.171875 C 6.382812 47.875 4.792969 47.820312 24.027344 47.820312 C 36.042969 47.820312 41.378906 47.796875 41.707031 47.722656 C 41.964844 47.671875 42.550781 47.449219 43.011719 47.226562 C 44.1875 46.667969 44.976562 45.867188 45.539062 44.703125 C 45.761719 44.238281 45.980469 43.679688 46.035156 43.449219 C 46.179688 42.863281 46.179688 23.804688 46.035156 23.21875 C 45.980469 22.988281 45.761719 22.425781 45.539062 21.972656 C 45.023438 20.878906 44.195312 20.027344 43.15625 19.492188 C 41.980469 18.890625 41.679688 18.84375 38.941406 18.84375 L 36.535156 18.84375 L 36.535156 12.621094 C 36.535156 6.558594 36.523438 6.382812 36.347656 5.839844 C 36.070312 4.996094 35.546875 4.195312 34.828125 3.503906 C 33.253906 1.972656 30.871094 0.960938 27.554688 0.390625 C 26.390625 0.195312 22.523438 0.140625 21.246094 0.3125 Z M 26.753906 4.496094 C 29.554688 4.890625 31.945312 5.980469 32.347656 7.050781 C 32.410156 7.21875 32.445312 9.207031 32.445312 13.074219 L 32.445312 18.84375 L 15.734375 18.84375 L 15.734375 13.023438 C 15.734375 6.496094 15.6875 6.960938 16.398438 6.328125 C 17.574219 5.296875 20.167969 4.515625 23.023438 4.320312 C 23.839844 4.265625 25.769531 4.363281 26.753906 4.496094 Z M 26.753906 4.496094' />
    </g>
  </svg>
)

export default LockFilledIcon