const MoreIcon = ({
  className = '',
  size = '16px',
  title = 'More',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 64 64'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 32 0 C 14.36 0 0 14.36 0 32 C 0 49.64 14.36 64 32 64 C 49.64 64 64 49.64 64 32 C 64 14.36 49.64 0 32 0 Z M 16.48 36.85 C 13.81 36.85 11.64 34.67 11.64 32 C 11.64 29.33 13.81 27.15 16.48 27.15 C 19.16 27.15 21.33 29.33 21.33 32 C 21.33 34.67 19.16 36.85 16.48 36.85 Z M 32 36.85 C 29.33 36.85 27.15 34.67 27.15 32 C 27.15 29.33 29.33 27.15 32 27.15 C 34.67 27.15 36.85 29.33 36.85 32 C 36.85 34.67 34.67 36.85 32 36.85 Z M 47.52 36.85 C 44.84 36.85 42.67 34.67 42.67 32 C 42.67 29.33 44.84 27.15 47.52 27.15 C 50.19 27.15 52.36 29.33 52.36 32 C 52.36 34.67 50.19 36.85 47.52 36.85 Z M 47.52 36.85' />
    </g>
  </svg>
)

export default MoreIcon