const HappyIcon = ({
  className = '',
  size = '16px',
  title = '',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 64 64'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 54.67 9.37 C 42.18 -3.12 21.86 -3.12 9.37 9.37 C -3.13 21.86 -3.12 42.18 9.37 54.67 C 21.86 67.16 42.18 67.16 54.67 54.67 C 67.16 42.18 67.16 21.86 54.67 9.37 Z M 51.18 51.18 C 40.61 61.74 23.43 61.74 12.86 51.18 C 2.3 40.61 2.3 23.42 12.86 12.86 C 23.43 2.3 40.61 2.3 51.18 12.86 C 61.74 23.43 61.74 40.61 51.18 51.18 Z M 20 23.35 C 20 21.28 21.75 19.61 23.81 19.61 C 25.88 19.61 27.56 21.28 27.56 23.35 C 27.56 25.42 25.88 27 23.81 27 C 21.75 27 20 25.42 20 23.35 Z M 37 23.35 C 37 21.28 38.72 19.61 40.79 19.61 C 42.86 19.61 44.54 21.28 44.54 23.35 C 44.54 25.42 42.86 27 40.79 27 C 38.72 27 37 25.42 37 23.35 Z M 45.90 38.68 C 43.58 44 38.14 47.52 32 47.52 C 25.84 47.52 20.37 44 18.13 38.64 C 17.75 37.71 18.18 36.66 19.11 36.27 C 19.34 36.18 19.57 36.13 19.80 36.13 C 20.51 36.13 21.18 36.55 21.47 37.25 C 23.15 41.29 27.3 43.89 32 43.89 C 36.7 43.89 40.82 41.29 42.57 37.25 C 42.97 36.33 44 35.9 44.95 36.3 C 45.87 36.7 46.29 37.77 45.9 38.68 Z M 45.9 38.68' />
    </g>
  </svg>
)

export default HappyIcon