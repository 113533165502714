const MinimizeFullscreenIcon = ({
  className = '',
  size = '24px',
  title = 'Minimize Fullscreen',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 30.53 13 L 23.26 13 C 20.86 13 18.9 11.13 18.9 8.72 L 18.9 1.45 C 18.9 0.65 19.55 0 20.35 0 C 21.16 0 21.8 0.65 21.8 1.45 L 21.8 8.72 C 21.8 9.52 22.46 10.18 23.26 10.18 L 30.53 10.18 C 31.33 10.18 31.98 10.83 31.98 11.63 C 31.98 12.43 31.33 13 30.53 13 Z M 30.53 13' />
      <path d='M 20.35 31.98 C 19.55 31.98 18.9 31.33 18.9 30.53 L 18.9 23.26 C 18.9 20.86 20.86 18.9 23.26 18.9 L 30.53 18.9 C 31.33 18.9 31.98 19.55 31.98 20.35 C 31.98 21.16 31.33 21.8 30.53 21.8 L 23.26 21.8 C 22.46 21.8 21.8 22.46 21.8 23.26 L 21.8 30.53 C 21.8 31.33 21.16 31.98 20.35 31.98 Z M 20.35 31.98' />
      <path d='M 8.72 13 L 1.45 13 C 0.65 13 0 12.43 0 11.63 C 0 10.83 0.65 10.18 1.45 10.18 L 8.72 10.18 C 9.52 10.18 10.18 9.52 10.18 8.72 L 10.18 1.45 C 10.18 0.65 10.82 0 11.63 0 C 12.43 0 13 0.65 13 1.45 L 13 8.72 C 13 11.13 11.13 13 8.72 13 Z M 8.72 13' />
      <path d='M 11.63 31.98 C 10.82 31.98 10.18 31.33 10.18 30.53 L 10.18 23.26 C 10.18 22.46 9.52 21.8 8.72 21.8 L 1.45 21.8 C 0.65 21.8 0 21.16 0 20.35 C 0 19.55 0.65 18.9 1.45 18.9 L 8.72 18.9 C 11.13 18.9 13 20.86 13 23.26 L 13 30.53 C 13 31.33 12.43 31.98 11.63 31.98 Z M 11.63 31.98' />
    </g>
  </svg>
)

export default MinimizeFullscreenIcon