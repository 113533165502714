const ErrorIcon = ({
  className = _s.fillPrimary,
  size = '32px',
  title = 'Error',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 64 64'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 32 0 C 14.355469 0 0 14.355469 0 32 C 0 49.644531 14.355469 64 32 64 C 49.644531 64 64 49.644531 64 32 C 64 14.355469 49.644531 0 32 0 Z M 32 61.332031 C 15.824219 61.332031 2.667969 48.175781 2.667969 32 C 2.667969 15.824219 15.824219 2.667969 32 2.667969 C 48.175781 2.667969 61.332031 15.824219 61.332031 32 C 61.332031 48.175781 48.175781 61.332031 32 61.332031 Z M 32 61.332031' />
      <path d='M 24 24 C 24 25.472656 22.804688 26.667969 21.332031 26.667969 C 19.859375 26.667969 18.667969 25.472656 18.667969 24 C 18.667969 22.527344 19.859375 21.332031 21.332031 21.332031 C 22.804688 21.332031 24 22.527344 24 24 Z M 24 24' />
      <path d='M 45.332031 24 C 45.332031 25.472656 44.140625 26.667969 42.667969 26.667969 C 41.195312 26.667969 40 25.472656 40 24 C 40 22.527344 41.195312 21.332031 42.667969 21.332031 C 44.140625 21.332031 45.332031 22.527344 45.332031 24 Z M 45.332031 24' />
      <path d='M 32 40 L 31.996094 40 C 20.792969 40 16.445312 45.628906 16.265625 45.867188 C 15.828125 46.453125 15.945312 47.28125 16.527344 47.722656 C 17.113281 48.167969 17.949219 48.054688 18.394531 47.472656 C 18.546875 47.277344 22.21875 42.667969 31.996094 42.667969 L 32 42.667969 C 41.777344 42.667969 45.453125 47.277344 45.601562 47.46875 C 45.863281 47.816406 46.261719 48 46.667969 48 C 46.945312 48 47.226562 47.914062 47.46875 47.734375 C 48.054688 47.292969 48.175781 46.457031 47.734375 45.867188 C 47.554688 45.628906 43.203125 40 32 40 Z M 32 40' />
    </g>
  </svg>
)

export default ErrorIcon