const CogIcon = ({
  className = '',
  size = '16px',
  title = '',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 23.40625 9.769531 L 21.261719 9.511719 C 21.042969 8.695312 20.722656 7.921875 20.3125 7.207031 L 21.640625 5.511719 C 21.847656 5.253906 21.824219 4.851562 21.585938 4.617188 L 19.378906 2.410156 C 19.148438 2.175781 18.746094 2.152344 18.484375 2.355469 L 16.789062 3.6875 C 16.074219 3.273438 15.304688 2.953125 14.488281 2.738281 L 14.230469 0.59375 C 14.191406 0.269531 13.890625 0 13.558594 0 L 10.4375 0 C 10.109375 0 9.804688 0.269531 9.765625 0.59375 L 9.507812 2.738281 C 8.695312 2.953125 7.921875 3.277344 7.207031 3.6875 L 5.511719 2.355469 C 5.253906 2.152344 4.851562 2.175781 4.617188 2.410156 L 2.410156 4.621094 C 2.175781 4.851562 2.152344 5.253906 2.359375 5.515625 L 3.6875 7.210938 C 3.277344 7.925781 2.953125 8.695312 2.738281 9.511719 L 0.597656 9.769531 C 0.269531 9.808594 0 10.109375 0 10.441406 L 0 13.5625 C 0 13.894531 0.269531 14.195312 0.597656 14.234375 L 2.738281 14.492188 C 2.957031 15.304688 3.277344 16.078125 3.6875 16.792969 L 2.359375 18.488281 C 2.15625 18.746094 2.179688 19.148438 2.414062 19.382812 L 4.617188 21.59375 C 4.851562 21.824219 5.253906 21.851562 5.511719 21.648438 L 7.207031 20.3125 C 7.921875 20.726562 8.695312 21.046875 9.511719 21.265625 L 9.769531 23.40625 C 9.808594 23.734375 10.109375 24 10.4375 24 L 13.5625 24 C 13.890625 24 14.195312 23.734375 14.230469 23.40625 L 14.488281 21.265625 C 15.304688 21.046875 16.078125 20.726562 16.792969 20.3125 L 18.488281 21.644531 C 18.746094 21.847656 19.148438 21.824219 19.382812 21.589844 L 21.589844 19.382812 C 21.824219 19.148438 21.847656 18.746094 21.644531 18.484375 L 20.3125 16.792969 C 20.722656 16.078125 21.042969 15.304688 21.261719 14.492188 L 23.402344 14.234375 C 23.730469 14.195312 24 13.894531 24 13.5625 L 24 10.441406 C 24 10.109375 23.734375 9.808594 23.40625 9.769531 Z M 12 18 C 8.6875 18 6 15.3125 6 12 C 6 8.6875 8.6875 6 12 6 C 15.316406 6 18 8.6875 18 12 C 18 15.3125 15.316406 18 12 18 Z M 12 18' />
    </g>
  </svg>
)

export default CogIcon