const ExploreIcon = ({
  className = '',
  size = '12px',
  title = 'Explore',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 11.296875 23.875 L 11.296875 18.773438 C 10.019531 18.824219 8.753906 19.003906 7.550781 19.300781 C 7.742188 19.769531 7.957031 20.21875 8.183594 20.640625 C 9.101562 22.332031 10.242188 23.5 11.296875 23.875 Z M 11.296875 23.875' />
      <path d='M 15.8125 20.640625 C 16.042969 20.21875 16.257812 19.769531 16.449219 19.300781 C 15.246094 19.003906 13.980469 18.824219 12.703125 18.773438 L 12.703125 23.875 C 13.757812 23.5 14.898438 22.332031 15.8125 20.640625 Z M 15.8125 20.640625' />
      <path d='M 11.296875 17.367188 L 11.296875 12.6875 L 6.167969 12.6875 C 6.234375 14.515625 6.546875 16.332031 7.066406 17.976562 C 8.425781 17.625 9.855469 17.417969 11.296875 17.367188 Z M 11.296875 17.367188' />
      <path d='M 11.296875 11.28125 L 11.296875 6.601562 C 9.855469 6.550781 8.425781 6.34375 7.066406 5.996094 C 6.546875 7.640625 6.234375 9.457031 6.167969 11.28125 Z M 11.296875 11.28125' />
      <path d='M 17.808594 4.273438 C 18.664062 3.984375 19.476562 3.632812 20.222656 3.222656 C 18.914062 1.996094 17.328125 1.054688 15.574219 0.507812 C 16.097656 1.101562 16.597656 1.820312 17.050781 2.65625 C 17.328125 3.167969 17.582031 3.710938 17.808594 4.273438 Z M 17.808594 4.273438' />
      <path d='M 8.1875 3.328125 C 7.957031 3.75 7.742188 4.199219 7.550781 4.667969 C 8.753906 4.96875 10.019531 5.148438 11.296875 5.195312 L 11.296875 0.09375 C 10.242188 0.46875 9.101562 1.636719 8.1875 3.328125 Z M 8.1875 3.328125' />
      <path d='M 12.703125 12.6875 L 12.703125 17.367188 C 14.144531 17.417969 15.574219 17.625 16.933594 17.976562 C 17.453125 16.332031 17.765625 14.515625 17.832031 12.6875 Z M 12.703125 12.6875' />
      <path d='M 12.703125 6.601562 L 12.703125 11.28125 L 17.832031 11.28125 C 17.765625 9.457031 17.453125 7.640625 16.933594 5.996094 C 15.574219 6.34375 14.144531 6.550781 12.703125 6.601562 Z M 12.703125 6.601562' />
      <path d='M 5.714844 18.375 C 5.152344 16.605469 4.824219 14.667969 4.761719 12.6875 L 0 12.6875 C 0.152344 15.34375 1.175781 17.773438 2.78125 19.691406 C 3.679688 19.171875 4.667969 18.730469 5.714844 18.375 Z M 5.714844 18.375' />
      <path d='M 17.808594 19.699219 C 17.582031 20.261719 17.328125 20.800781 17.050781 21.3125 C 16.597656 22.148438 16.097656 22.871094 15.574219 23.464844 C 17.328125 22.914062 18.914062 21.976562 20.222656 20.746094 C 19.476562 20.335938 18.664062 19.988281 17.808594 19.699219 Z M 17.808594 19.699219' />
      <path d='M 6.191406 19.699219 C 5.335938 19.988281 4.523438 20.335938 3.777344 20.746094 C 5.085938 21.976562 6.671875 22.914062 8.425781 23.464844 C 7.902344 22.871094 7.402344 22.148438 6.949219 21.3125 C 6.671875 20.800781 6.417969 20.261719 6.191406 19.699219 Z M 6.191406 19.699219' />
      <path d='M 18.285156 5.59375 C 18.847656 7.363281 19.175781 9.300781 19.238281 11.28125 L 24 11.28125 C 23.847656 8.625 22.824219 6.199219 21.21875 4.277344 C 20.320312 4.796875 19.332031 5.238281 18.285156 5.59375 Z M 18.285156 5.59375' />
      <path d='M 18.285156 18.375 C 19.332031 18.730469 20.320312 19.171875 21.21875 19.691406 C 22.824219 17.773438 23.847656 15.34375 24 12.6875 L 19.238281 12.6875 C 19.175781 14.667969 18.847656 16.605469 18.285156 18.375 Z M 18.285156 18.375' />
      <path d='M 12.703125 0.09375 L 12.703125 5.195312 C 13.980469 5.148438 15.246094 4.96875 16.449219 4.667969 C 16.257812 4.199219 16.042969 3.75 15.8125 3.328125 C 14.898438 1.636719 13.757812 0.46875 12.703125 0.09375 Z M 12.703125 0.09375' />
      <path d='M 5.714844 5.59375 C 4.667969 5.238281 3.679688 4.796875 2.78125 4.277344 C 1.175781 6.199219 0.152344 8.625 0 11.28125 L 4.761719 11.28125 C 4.824219 9.300781 5.152344 7.363281 5.714844 5.59375 Z M 5.714844 5.59375' />
      <path d='M 6.191406 4.273438 C 6.417969 3.710938 6.671875 3.167969 6.949219 2.65625 C 7.402344 1.820312 7.902344 1.101562 8.425781 0.507812 C 6.671875 1.054688 5.085938 1.996094 3.777344 3.222656 C 4.523438 3.632812 5.335938 3.984375 6.191406 4.273438 Z M 6.191406 4.273438' />
    </g>
  </svg>
)

export default ExploreIcon
