const AudioMuteIcon = ({
  className = '',
  size = '24px',
  title = '',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M20.654 12l3.032-3.032c.293-.293.293-.768 0-1.06s-.768-.294-1.06 0l-3.033 3.03-3.032-3.03c-.292-.294-.767-.294-1.06 0s-.293.767 0 1.06L18.533 12 15.5 15.032c-.292.293-.292.768 0 1.06.147.147.34.22.53.22s.385-.072.53-.22l3.033-3.03 3.032 3.03c.146.147.338.22.53.22s.384-.072.53-.22c.293-.292.293-.767 0-1.06L20.655 12zm-8.401 10.75c-.166 0-.33-.055-.466-.162L4.74 17H2.254c-1.24 0-2.25-1.01-2.25-2.25v-5.5C.003 8.01 1.013 7 2.253 7H4.74l7.047-5.588c.225-.18.533-.215.792-.087.258.125.423.387.423.675v20c0 .288-.165.55-.424.675-.104.05-.216.075-.327.075zm-10-14.25c-.413 0-.75.337-.75.75v5.5c0 .413.337.75.75.75h2.75c.17 0 .333.058.466.162l6.033 4.786V3.552L5.47 8.338c-.134.104-.298.162-.467.162h-2.75z' />
    </g>
  </svg>
)

export default AudioMuteIcon