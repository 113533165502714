const HashTagIcon = ({
  className = '',
  size = '32px',
  title = 'Trends',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 80 80'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d="M 80,30.769231 V 18.461538 H 63.196923 L 64.615383,0 H 52.307693 L 50.88923,18.461538 H 32.427692 L 33.846153,0 H 21.538462 L 20.12,18.461538 H 0 V 30.769231 H 19.172308 L 17.750769,49.23077 H 0 V 61.53846 H 16.803077 L 15.384615,80 H 27.692308 L 29.110769,61.53846 H 47.572307 L 46.153847,80 H 58.46154 L 59.88,61.53846 H 80 V 49.23077 H 60.83077 L 62.24923,30.769231 Z M 48.523077,49.23077 H 30.061538 l 1.421539,-18.461539 h 18.46154 z" />
    </g>
  </svg>
)

export default HashTagIcon
