const StrikethroughIcon = ({
  className = '',
  size = '16px',
  title = 'Strikethrough',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 34 32'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 7.527344 12.890625 C 7.617188 13.050781 7.714844 13.191406 7.804688 13.34375 L 16 13.34375 C 14.863281 12.949219 14.167969 12.535156 13.503906 12.097656 C 12.632812 11.519531 12.195312 10.800781 12.195312 9.945312 C 12.195312 9.539062 12.285156 9.136719 12.453125 8.773438 C 12.621094 8.410156 12.878906 8.089844 13.21875 7.8125 C 13.554688 7.539062 13.980469 7.324219 14.496094 7.171875 C 15.023438 7.023438 15.625 6.941406 16.328125 6.941406 C 17.058594 6.941406 17.6875 7.03125 18.222656 7.21875 C 18.753906 7.394531 19.199219 7.664062 19.554688 7.992188 C 19.910156 8.320312 20.179688 8.71875 20.347656 9.191406 C 20.515625 9.652344 20.605469 10.160156 20.605469 10.703125 L 25.957031 10.703125 C 25.957031 9.539062 25.734375 8.460938 25.28125 7.484375 C 24.828125 6.496094 24.1875 5.652344 23.351562 4.941406 C 22.515625 4.222656 21.511719 3.671875 20.335938 3.269531 C 19.164062 2.871094 17.859375 2.675781 16.417969 2.675781 C 15.011719 2.675781 13.734375 2.851562 12.558594 3.199219 C 11.386719 3.546875 10.382812 4.035156 9.527344 4.667969 C 8.675781 5.296875 8.019531 6.0625 7.546875 6.960938 C 7.074219 7.859375 6.84375 8.84375 6.84375 9.929688 C 6.84375 11.058594 7.074219 12.042969 7.527344 12.890625 Z M 7.527344 12.890625' />
      <path d='M 0 15.121094 L 0 18.675781 L 17.109375 18.675781 C 17.429688 18.800781 17.820312 18.925781 18.089844 19.039062 C 18.746094 19.332031 19.261719 19.644531 19.636719 19.945312 C 20.007812 20.257812 20.257812 20.59375 20.390625 20.949219 C 20.523438 21.316406 20.585938 21.722656 20.585938 22.167969 C 20.585938 22.585938 20.507812 22.976562 20.347656 23.332031 C 20.1875 23.699219 19.9375 24.007812 19.609375 24.265625 C 19.28125 24.523438 18.851562 24.730469 18.347656 24.878906 C 17.832031 25.03125 17.234375 25.101562 16.542969 25.101562 C 15.769531 25.101562 15.066406 25.023438 14.4375 24.871094 C 13.804688 24.71875 13.269531 24.472656 12.828125 24.132812 C 12.382812 23.796875 12.035156 23.351562 11.785156 22.808594 C 11.539062 22.265625 11.332031 21.449219 11.332031 20.667969 L 6.042969 20.667969 C 6.042969 21.644531 6.1875 22.675781 6.460938 23.476562 C 6.738281 24.277344 7.128906 24.996094 7.617188 25.625 C 8.105469 26.257812 8.691406 26.800781 9.359375 27.261719 C 10.027344 27.722656 10.746094 28.117188 11.527344 28.417969 C 12.3125 28.730469 13.121094 28.960938 13.980469 29.101562 C 14.835938 29.253906 15.699219 29.324219 16.550781 29.324219 C 17.972656 29.324219 19.269531 29.164062 20.425781 28.835938 C 21.582031 28.507812 22.578125 28.035156 23.394531 27.429688 C 24.214844 26.816406 24.84375 26.070312 25.296875 25.171875 C 25.75 24.277344 25.964844 23.261719 25.964844 22.125 C 25.964844 21.058594 25.777344 20.097656 25.414062 19.253906 C 25.324219 19.050781 25.226562 18.851562 25.109375 18.65625 L 32 18.65625 L 32 15.121094 Z M 0 15.121094' />
    </g>
  </svg>
)

export default StrikethroughIcon