const ULListIcon = ({
  className = '',
  size = '16px',
  title = 'Unordered List',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 34 32'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 2.59375 3.027344 C 1.160156 3.027344 0 4.1875 0 5.621094 C 0 7.058594 1.160156 8.214844 2.59375 8.214844 C 4.03125 8.214844 5.1875 7.058594 5.1875 5.621094 C 5.1875 4.1875 4.03125 3.027344 2.59375 3.027344 Z M 2.59375 3.027344' />
      <path d='M 2.59375 13.40625 C 1.160156 13.40625 0 14.5625 0 16 C 0 17.4375 1.160156 18.59375 2.59375 18.59375 C 4.03125 18.59375 5.1875 17.4375 5.1875 16 C 5.1875 14.5625 4.03125 13.40625 2.59375 13.40625 Z M 2.59375 13.40625' />
      <path d='M 2.59375 23.785156 C 1.148438 23.785156 0 24.953125 0 26.378906 C 0 27.804688 1.167969 28.972656 2.59375 28.972656 C 4.023438 28.972656 5.1875 27.804688 5.1875 26.378906 C 5.1875 24.953125 4.039062 23.785156 2.59375 23.785156 Z M 2.59375 23.785156' />
      <path d='M 7.785156 24.648438 L 32 24.648438 L 32 28.109375 L 7.785156 28.109375 Z M 7.785156 24.648438' />
      <path d='M 7.785156 3.890625 L 32 3.890625 L 32 7.351562 L 7.785156 7.351562 Z M 7.785156 3.890625' />
      <path d='M 7.785156 14.269531 L 32 14.269531 L 32 17.730469 L 7.785156 17.730469 Z M 7.785156 14.269531' />
    </g>
  </svg>
)

export default ULListIcon