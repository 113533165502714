const BackIcon = ({
  className = '',
  size = '16px',
  title = 'Back',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 60 60'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 56.39 25.79 C 56.15 25.75 55.9 25.73 55.65 25.73 L 13.32 25.73 L 14.25 25.3 C 15.15 24.88 15.97 24.3 16.67 23.59 L 28.54 11.72 C 30.11 10.23 30.37 7.82 29.16 6 C 27.76 4.12 25 3.7 23.16 5.1 C 23 5.21 22.86 5.34 22.72 5.47 L 1.26 26.94 C -0.42 28.61 -0.42 31.33 1.25 33 C 1.26 33 1.26 33 1.26 33 L 22.72 54.48 C 24.4 56.15 27.12 56.14 28.8 54.46 C 28.93 54.34 29 54.2 29.16 54 C 30.37 52.25 30.11 49.85 28.54 48.36 L 16.69 36.46 C 16 35.84 15.34 35.31 14.55 34.9 L 13.26 34.32 L 55.41 34.32 C 57.61 34.4 59.53 32.87 59.95 30.71 C 60.32 28.37 58.73 26.17 56.39 25.79 Z M 56.39 25.79' />
    </g>
  </svg>
)

export default BackIcon