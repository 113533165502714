const UnlockFilledIcon = ({
  className = '',
  size = '24px',
  title = 'Unlock',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 48 48'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 21.25 0.31 C 17.76 0.75 14.89 1.94 13.25 3.59 C 12.57 4.27 12.16 4.91 11.87 5.75 L 11.64 6.36 L 11.64 18.84 L 9.22 18.84 C 6.46 18.84 6.2 18.89 5.03 19.48 C 4.05 19.99 3.19 20.85 2.68 21.83 C 2.01 23.16 2.04 22.45 2.04 33.32 C 2.04 44.2 2.01 43.51 2.69 44.84 C 3.18 45.8 4.05 46.67 5.02 47.17 C 6.38 47.88 4.79 47.82 24.03 47.82 C 36.04 47.82 41.38 47.8 41.71 47.72 C 41.96 47.67 42.55 47.45 43.01 47.23 C 44.19 46.67 44.98 45.87 45.54 44.7 C 45.76 44.24 45.98 43.68 46.04 43.45 C 46.18 42.86 46.18 23.8 46.04 23.22 C 45.98 22.99 45.76 22.43 45.54 21.97 C 45.02 20.88 44.2 20.03 43.16 19.49 C 41.8 18.8 42.86 18.84 28.54 18.84 L 15.73 18.84 L 15.73 13.02 C 15.73 6.5 15.69 6.96 16.4 6.33 C 17.8 5.1 20.77 4.33 24.09 4.34 C 26.44 4.34 28.23 4.64 30.03 5.33 C 31.37 5.85 32.12 6.46 32.62 7.48 C 33.28 8.77 34.05 9.19 35.15 8.87 C 35.63 8.73 36.31 8.04 36.44 7.59 C 36.77 6.38 36.14 4.77 34.83 3.5 C 33.25 1.97 30.87 0.96 27.55 0.39 C 26.39 0.2 22.52 0.14 21.25 0.31 Z M 21.25 0.31' />
    </g>
  </svg>
)

export default UnlockFilledIcon