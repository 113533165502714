const DissenterIcon = ({
  className = '',
  size = '32px',
  title = 'Dissenter',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 64 64'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 33.52 4.73 L 8 4.73 C 4.8 4.73 2.18 7.36 2.18 10.57 L 2.18 53.56 C 2.18 56.77 4.8 59.39 8 59.39 L 33.52 59.39 C 52 59.39 61.95 46.5 61.95 32 C 61.95 17.63 52.55 4.73 33.52 4.73' />
      <path fill='#F6F6F9' d='M 41.76 24.57 L 17.76 24.57 C 16.93 24.57 16.26 23.89 16.26 23 C 16.26 22.19 16.93 21.5 17.76 21.5 L 41.76 21.5 C 42.59 21.5 43.27 22.19 43.27 23 C 43.27 23.89 42.59 24.57 41.76 24.57' />
      <path fill='#F6F6F9' d='M 41.76 33.54 L 17.76 33.54 C 16.93 33.54 16.26 32.85 16.26 32 C 16.26 31.15 16.93 30.46 17.76 30.46 L 41.76 30.46 C 42.59 30.46 43.27 31.15 43.27 32 C 43.27 32.85 42.59 33.54 41.76 33.54' />
      <path fill='#F6F6F9' d='M 17.77 42.63 C 16.93 42.63 16.26 41.94 16.26 41 C 16.26 40.24 16.93 39.55 17.77 39.55 L 33.82 39.55 C 34.65 39.55 35.33 40.24 35.33 41 C 35.33 41.94 34.65 42.63 33.82 42.63 Z M 17.77 42.63' />
    </g>
  </svg>
)

export default DissenterIcon