const GabLogo = ({
  className = _s.fillBrand,
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='25'
    viewBox='0 5 31 27'
    xmlSpace='preserve'
  >
  <g
     transform="matrix(0.46846483,0,0,0.46846483,0.52253327,3.2314139)"
     id="g15">
    <path className={_s.inheritFill}
       d="m 61.200001,34.200001 c -0.5,2 -1.299999,4.099998 -2.400002,6.200001 C 51.799999,53.8 39.5,59.599999 24.5,60 c 3.1,-3.799999 4.4,-7.599998 5.5,-10.700001 4.400002,-12.099998 16.799999,-19.4 31.200001,-15.099998 z"
       id="XMLID_554_"
       fill="#ffffff" />
    <path className={_s.inheritFill}
       d="M 61.299999,19 C 62.5,20.200001 62.799999,22.1 62,23.5 61.799999,23.299999 61.799999,23.299999 61.700001,23.200001 v 0 C 61.5,23 59.900002,21.299999 47.400002,8.8 c 1.5,-0.8 3.299999,-0.5 4.5,0.7 z"
       id="XMLID_556_"
       fill="#ffffff" />
    <path className={_s.inheritFill}
       d="m 61.900002,12.5 c 1.199997,1.2 1.5,3.1 0.699997,4.5 L 53.900002,8.3 c 1.5,-0.8 3.299999,-0.5 4.5,0.7 z"
       id="XMLID_557_"
       fill="#ffffff" />
    <path className={_s.inheritFill}
       d="m 61.099998,25.9 c 0.700001,1.6 0.800003,3.700001 0.5,6 -15.299999,-4.4 -29,3.4 -33.699999,16.500002 C 26.7,52 25.2,56 21.499999,60 c -8.4,-0.200001 -15.3,-2.299999 -19.7,-4 -1,-0.400002 -1.1,-1.700001 -0.2,-2.200001 12,-7.599998 16.699999,-24.099998 16.9,-24.799999 5,-17.2 16.299999,-22.2 26.5,-19.2 l 16.099998,16.099999 z"
       id="XMLID_550_"
       fill="#ffffff" />
    <path className={_s.inheritFill}
       d="M 64.699997,8.1 C 64.099999,8.8 63.5,9.6 63.099999,10.5 l -3.099998,-3 c 1.200001,-0.9 2.5,-1.3 3.5,-1.4 1.199997,-0.2 2,1.1 1.199996,2 z"
       id="XMLID_551_"
       fill="#ffffff" />
  </g>
  </svg>
)

export default GabLogo
