const RichTextIcon = ({
  className = '',
  size = '26px',
  title = 'Rich Text',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 64 64'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 47.558594 64 L 16.441406 64 L 16.441406 61.351562 C 17.808594 60.863281 19.191406 60.4375 20.589844 60.070312 C 21.988281 59.703125 23.496094 59.402344 25.113281 59.152344 L 25.113281 4.753906 L 13.3125 4.753906 L 8.371094 17.832031 L 6.042969 17.832031 C 5.855469 16.613281 5.699219 15.222656 5.574219 13.667969 C 5.449219 12.121094 5.34375 10.535156 5.246094 8.914062 C 5.152344 7.308594 5.078125 5.714844 5.019531 4.164062 C 4.953125 2.617188 4.921875 1.222656 4.921875 0 L 59.078125 0 C 59.078125 1.222656 59.042969 2.597656 58.980469 4.117188 C 58.917969 5.640625 58.839844 7.210938 58.742188 8.824219 C 58.644531 10.4375 58.558594 12.023438 58.460938 13.582031 C 58.367188 15.132812 58.226562 16.554688 58.039062 17.832031 L 55.585938 17.832031 L 50.6875 4.753906 L 38.980469 4.753906 L 38.980469 59.15625 C 40.59375 59.464844 42.105469 59.769531 43.5 60.078125 C 44.902344 60.378906 46.25 60.800781 47.558594 61.355469 Z M 47.558594 64' />
    </g>
  </svg>
)

export default RichTextIcon