const LinkIcon = ({
  className = '',
  size = '16px',
  title = 'Link',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 64 64'
    xmlSpace='preserve'
    aria-label={title}
  >
  <g>
    <path d='M 26.53 48.78 L 18.99 56.32 C 15.86 59.45 10.8 59.45 7.68 56.32 C 4.55 53.2 4.55 48.13 7.68 45 L 22.76 29.93 C 25.89 26.8 30.95 26.8 34 29.93 C 35.12 30.96 36.8 30.96 37.84 29.93 C 38.89 28.88 38.89 27.2 37.84 26.15 C 32.64 20.95 24.2 20.95 18.99 26.15 L 3.91 41.24 C -1.3 46.45 -1.3 54.89 3.91 60 C 9.11 65.3 17.55 65.3 22.76 60 L 30.3 52.55 C 31.34 51.51 31.34 49.82 30.3 48.78 C 29.26 47.74 27.57 47.74 26.53 48.78 Z M 26.53 48.78' />
    <path d='M 60 3.91 C 54.89 -1.3 46.45 -1.3 41.24 3.91 L 32.19 12.95 C 31.15 14 31.15 15.68 32.19 16.72 C 33.23 17.77 34.92 17.77 35.96 16.72 L 45 7.68 C 48.13 4.55 53.2 4.55 56.32 7.68 C 59.45 10.8 59.45 15.86 56.32 18.99 L 39.73 35.58 C 36.61 38.7 31.54 38.7 28.42 35.58 C 27.38 34.54 25.69 34.54 24.65 35.58 C 23.61 36.62 23.61 38.31 24.65 39.35 C 29.86 44.56 38.3 44.56 43.5 39.35 L 60 22.76 C 65.3 17.55 65.3 9.11 60 3.91 Z M 60 3.91' />
  </g>
</svg>
)

export default LinkIcon