const ProIcon = ({
  className = '',
  size = '32px',
}) => (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 32 32'
      preserveAspectRatio='xMidYMid'
    >
      <g>
      <path d='M 29.28 12.36 C 29.22 12.27 29.15 12.19 29.08 12.12 L 17.63 0.67 C 16.74 -0.22 15.29 -0.22 14.39 0.67 C 14.39 0.67 14.39 0.67 14.39 0.67 L 2.95 12.12 C 2.05 13.02 2.05 14.46 2.95 15.36 C 3.02 15.43 3.1 15.49 3.18 15.55 C 4.13 16.2 5.41 16.05 6.21 15.22 L 12.55 8.9 C 12.89 8.57 13.17 8.18 13.39 7.76 L 13.7 7.07 L 13.7 29.55 C 13.65 30.72 14.47 31.75 15.62 31.97 C 16.87 32.17 18.04 31.32 18.25 30.08 C 18.27 29.95 18.28 29.81 18.28 29.68 L 18.28 7.11 L 18.5 7.6 C 18.73 8.08 19.04 8.52 19.42 8.89 L 25.75 15.22 C 26.55 16.05 27.83 16.2 28.79 15.55 C 29.8 14.81 30.03 13.38 29.28 12.36 Z M 29.28 12.36' />
      </g>
    </svg>
  )

export default ProIcon
