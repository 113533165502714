const CodeIcon = ({
  className = '',
  size = '24px',
  title = 'Code',
}) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={size}
    height={size}
    viewBox='0 0 48 48'
    xmlSpace='preserve'
    aria-label={title}
  >
    <g>
      <path d='M 47.507812 22.765625 L 36.410156 11.003906 C 35.726562 10.28125 34.589844 10.25 33.863281 10.929688 C 33.140625 11.613281 33.109375 12.753906 33.792969 13.476562 L 43.726562 24 L 33.792969 34.523438 C 33.109375 35.25 33.140625 36.386719 33.863281 37.070312 C 34.210938 37.398438 34.65625 37.5625 35.101562 37.5625 C 35.578125 37.5625 36.054688 37.371094 36.410156 36.996094 L 47.507812 25.238281 C 48.164062 24.542969 48.164062 23.457031 47.507812 22.765625 Z M 47.507812 22.765625' />
      <path d='M 14.210938 34.523438 L 4.277344 24 L 14.210938 13.476562 C 14.890625 12.753906 14.859375 11.613281 14.136719 10.929688 C 13.414062 10.25 12.273438 10.28125 11.589844 11.003906 L 0.492188 22.765625 C -0.164062 23.457031 -0.164062 24.542969 0.492188 25.234375 L 11.589844 36.996094 C 11.945312 37.371094 12.421875 37.5625 12.902344 37.5625 C 13.34375 37.5625 13.789062 37.398438 14.136719 37.070312 C 14.859375 36.386719 14.890625 35.246094 14.210938 34.523438 Z M 14.210938 34.523438' />
      <path d='M 26.914062 4.941406 C 25.929688 4.792969 25.011719 5.464844 24.859375 6.449219 L 19.582031 41.007812 C 19.429688 41.992188 20.105469 42.910156 21.089844 43.058594 C 21.179688 43.074219 21.273438 43.082031 21.363281 43.082031 C 22.238281 43.082031 23.003906 42.441406 23.140625 41.550781 L 28.417969 6.992188 C 28.570312 6.007812 27.894531 5.089844 26.914062 4.941406 Z M 26.914062 4.941406' />
    </g>
  </svg>
)

export default CodeIcon